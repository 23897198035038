<template>
  <div class="box">
    <el-scrollbar style="height: 98%">
      <el-card style="height: 100%">
        <div class="box-top">
          <el-select
            v-model="$store.state.grade.value"
            placeholder="年级"
            size="mini"
            @change="(val) => changeInquire(val, 'grade')"
          >
            <el-option
              v-for="item in gradeData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="$store.state.subject.value"
            placeholder="学科"
            size="mini"
            @change="(val) => changeInquire(val, 'subject')"
          >
            <el-option
              v-for="item in subjectData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="$store.state.chapter.id"
            placeholder="章节列表"
            size="mini"
            @change="(val) => changeInquire(val, 'chapter')"
          >
            <el-option
              v-for="item in $store.state.chapterData"
              :key="item.id"
              :label="item.chapterTitle"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-button
            type="success"
            size="mini"
            v-if="$store.state.grade.label && $store.state.subject.label"
            @click="SeeChapter"
          >查看章节</el-button>
          <el-button
            type="success"
            v-if="
            $store.state.grade.label &&
            $store.state.subject.label &&
            $store.state.chapter.id
          "
            @click="newVariant"
            size="mini"
          >新建变式</el-button>
        </div>

        <el-main class="box-card">
          <el-table
            border
            :data="$store.state.tableData"
            style="width: 100%; margin-top: 25px; margin-bottom: 25px"
          >
            <el-table-column label="编号" width="80" align="center">
              <template slot-scope="{ row }">{{ row.index }}</template>
            </el-table-column>
            <el-table-column label="变式名称" width="180" align="center" :show-overflow-tooltip="true">
              <template slot-scope="{ row }">{{ row.name }}</template>
            </el-table-column>
            <el-table-column label="年级" width="100" align="center">
              <template slot-scope="{ row }">{{ row.gradeName }}</template>
            </el-table-column>
            <el-table-column label="学科" width="100" align="center">
              <template slot-scope="{ row }">{{ row.subject }}</template>
            </el-table-column>
            <el-table-column label="章节" width="100" align="center">
              <template slot-scope="{ row }">{{ row.chapterTitle }}</template>
            </el-table-column>
            <el-table-column label="分组" width="100" align="center">
              <template slot-scope="{ row }">{{ row.sn }}</template>
            </el-table-column>
            <el-table-column label="分组位置" width="100" align="center">
              <template slot-scope="{ row }">{{ row.type }}</template>
            </el-table-column>
            <el-table-column label="题目数量" width="100" align="center">
              <template slot-scope="{ row }">{{ row.questionCount }}</template>
            </el-table-column>
            <el-table-column label="更新时间" width="180" align="center">
              <template slot-scope="{ row }">{{ row.updateTime }}</template>
            </el-table-column>
            <el-table-column label="难度" width="180" align="center">
              <template slot-scope="{ row }">
                <el-rate
                  disabled
                  show-score
                  v-model="row.difficulty"
                  text-color="#ff9900"
                  style="margin-top: 11px"
                ></el-rate>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="400" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                <el-button size="mini" @click="handleEnter(scope.$index, scope.row)">进入</el-button>
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page="$store.state.page"
            :page-size="10"
            :total="$store.state.total"
            @current-change="changePaging"
            class="pager"
          ></el-pagination>
        </el-main>
      </el-card>
    </el-scrollbar>

    <!-- 新建编辑变式点击出的弹窗 -->
    <el-dialog
      :title="formLabelAlign.title"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form label-position="right" label-width="130px" :model="formLabelAlign">
        <el-form-item label="分组：">
          <el-input v-model="formLabelAlign.sn" type="number"></el-input>
        </el-form-item>
        <el-form-item label="分组位置：">
          <el-input v-model.number="formLabelAlign.type" type="number"></el-input>
        </el-form-item>
        <el-form-item label="变式名称：">
          <el-input v-model="formLabelAlign.name"></el-input>
        </el-form-item>
        <el-form-item label="难度：">
          <el-rate v-model="formLabelAlign.difficulty" style="margin-top: 11px"></el-rate>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determineVariant" v-if="allow_go">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看编辑章节点击出的弹窗 -->
    <el-dialog
      title="查看章节"
      :visible.sync="dialogChapter"
      width="50%"
      :before-close="handleClose"
      class="edit_dialog"
    >
      <el-form
        style="margin-top: 20px"
        label-position="left"
        label-width="180px"
        :model="nweChapterTitle"
      >
        <el-form-item label="新增章节名称：">
          <el-input v-model="nweChapterTitle.name" style="width: 400px"></el-input>
          <el-button
            style="margin-left: 20px"
            type="success"
            @click="addChapter(nweChapterTitle.name)"
          >确认</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="specialData" style="width: 100%" :border="true">
        <el-table-column prop="date" label="编号" width="180">
          <template slot-scope="{ row }">{{ row.serialnum }}</template>
        </el-table-column>
        <el-table-column label="章节名称" width="400">
          <template slot-scope="{ row }">
            <el-input v-model="row.chapterTitle" placeholder="请输入章节名称"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template slot-scope="scope">
            <el-button size="mini" @click="upChapter(scope.$index, scope.row)">保存</el-button>
            <el-button
              size="mini"
              type="danger"
              style="margin-left: 10px"
              @click="DetailsChapter(scope.$index, scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        background
        style="margin-top: 20px"
        layout="prev, pager, next"
        :current-page="specialPaging"
        :page-size="10"
        :total="specialtotal"
        @current-change="changespecialPaging"
        class="pager"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSpecialtopic,
  CaseAdd,
  CaseUpdate,
  CaseList,
  CaseDelete,
  specialtopicList,
  specialtopicAdd,
  specialtopicUp,
  specialtopicDelete,
} from "../../../api/api";
import { transformTimestamp } from "../../../components/time";
import store from "../../../store/index.js";
import { mapGetters } from "vuex";
export default {
  data () {
    return {
      nweChapterTitle: {
        name: "",
      },
      specialPaging: 1, //章节分页
      specialData: [],
      specialtotal: null, //专题分页
      allow_go: true,
      gradeData: this.parameter.grade(),
      subjectData: this.parameter.subject(),
      Ischapter: false,
      dialogVisible: false, //弹窗
      //新建变式表单和编辑表单
      formLabelAlign: {
        title: null,
        chapter: "", //章节
        name: null,
        sn: null,
        type: null,
        difficulty: 1, //难度
      },
      dialogChapter: false,
    };
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters([
      "grade",
      "subject",
      "chapter",
      "chapterData",
      "page",
      "total",
      // ...
    ]),
  },
  methods: {
    init () {
      this.formLabelAlign = {
        title: null,
        chapter: "", //章节
        name: null,
        sn: null,
        type: null,
        difficulty: 1, //难度
      };
      var param = {
        grade: this.$store.state.grade.value,
        subject: this.$store.state.subject.value,
        specialtopicType: 0,
      };
      getSpecialtopic(param).then((res) => {
        res.data.map((item, index) => {
          item["number"] = index + 1;
        });
        store.commit("setChapterData", res.data);
        if (this.$store.state.chapterData.length > 0) {
          store.commit("setChapter", {
            id:
              this.$store.state.chapter.id ||
              this.$store.state.chapterData[0].id,
            name: "",
          });
          this.getCaseList();
        }
      });
    },
    //查看章节
    SeeChapter () {
      //网络请求
      this.dialogChapter = true;
      this.specialtopicList();
    },
    //新增章节名称
    addChapter () {
      if (
        this.nweChapterTitle.name == "" ||
        this.nweChapterTitle.name == null
      ) {
        this.$message.error("新增失败,名称不可为空");
        return;
      }
      specialtopicAdd({
        subject: this.$store.state.subject.value,
        grade: this.$store.state.grade.value,
        sort: 1,
        chapterTitle: this.nweChapterTitle.name,
      }).then((res) => {
        if (res.message == "请求成功") {
          this.$message({
            message: "新增成功",
            type: "success",
          });
          this.specialtopicList();
          this.nweChapterTitle.name = "";
        } else {
          this.$message.error("新增失败");
        }
      });
    },
    //封装专辑接口
    specialtopicList () {
      specialtopicList({
        subject: this.$store.state.subject.value,
        grade: this.$store.state.grade.value,
        pageNum: this.specialPaging,
        pageSize: 10,
      }).then((res) => {
        this.specialtotal = res.data.total;
        res.data.list.map((item, index) => {
          item["serialnum"] = (this.specialPaging - 1) * 10 + index + 1;
        });
        this.specialData = res.data.list;
      });
    },

    //编辑章节名称
    upChapter (index, row) {
      this.$confirm("此操作将永久保存, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (row.chapterTitle == "" || row.chapterTitle == null) {
            this.$message.error("编辑失败,名称不可为空");
            return;
          }
          specialtopicUp({
            id: row.id,
            subject: this.$store.state.subject.value,
            grade: this.$store.state.grade.value,
            npCases: [],
            sort: 1,
            chapterTitle: row.chapterTitle,
          }).then((res) => {
            if (res.message == "请求成功") {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.specialtopicList();
            } else {
              this.$message.error("编辑失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消保存",
          });
        });
    },
    //删除章节名称
    DetailsChapter (index, row) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          specialtopicDelete([row.id]).then((res) => {
            if (res.message == "请求成功") {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.specialtopicList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //封装请求接口
    getCaseList () {
      CaseList({
        pageNum: this.$store.state.page,
        pageSize: 10,
        specialTopicId: this.$store.state.chapter.id,
      }).then((res) => {
        store.commit("setTotal", res.data.total);
        res.data.list.map((item, index) => {
          item["questionCount"] = item.questionCount;
          item["gradeName"] = this.$store.state.grade.label;
          item["subject"] = this.$store.state.subject.label;
          item["chapterTitle"] = item.specialtopicEntity.chapterTitle;
          item["updateTime"] = transformTimestamp(item.updateTime);
          item["index"] = (this.$store.state.page - 1) * 10 + index + 1;
          item["difficulty"] = Number(item.difficulty);
        });
        store.commit("setTableData", res.data.list);
      });
    },
    //点击查询
    changeInquire (val, name) {
      switch (name) {
        case "grade":
          let gradeName = this.gradeData.find((item) => {
            return item.value == val;
          });
          this.$store.commit("setGrade", {
            value: gradeName.value,
            label: gradeName.label,
          });
          this.$store.commit("setSubject", { value: "", label: "" });
          this.$store.commit("setChapter", { id: "", name: "" });
          this.$store.commit("setChapterData", []);
          this.$store.commit("setTableData", []);
          this.$store.commit("setPage", 1);
          this.$store.commit("setTotal", 0);
          var param = {
            grade: this.$store.state.grade.value,
            subject: this.$store.state.subject.value,
            specialtopicType: 0,
          };
          // getSpecialtopic(param).then((res) => {
          //   res.data.map((item, index) => {
          //     item["number"] = index + 1;
          //   });
          //   store.commit("setChapterData", res.data);
          // });
          //  this.$store.state.chapter.id
          break;
        case "subject":
          let subjectName = this.subjectData.find((item) => {
            return item.value == val;
          });
          store.commit("setSubject", {
            value: subjectName.value,
            label: subjectName.label,
          });
          store.commit("setChapter", { id: "", name: "" });
          store.commit("setTableData", []);
          this.$store.commit("setPage", 1);
          this.$store.commit("setTotal", 0);
          var param = {
            grade: this.$store.state.grade.value,
            subject: this.$store.state.subject.value,
            specialtopicType: 0,
          };
          getSpecialtopic(param).then((res) => {
            res.data.map((item, index) => {
              item["number"] = index + 1;
            });
            store.commit("setChapterData", res.data);
          });
          break;
        case "chapter":
          this.getCaseList();
          break;
        default:
          break;
      }
    },
    //新建变式
    newVariant () {
      if (
        this.$store.state.grade.label &&
        this.$store.state.subject.label &&
        this.$store.state.chapter.id
      ) {
        this.init();
        this.formLabelAlign.title = "新建变式";
        this.dialogVisible = true;
      } else {
        this.$message.error("请选择年级丶学科以及章节");
        return;
      }
    },
    //确定创建和编辑变式
    determineVariant () {
      if (this.formLabelAlign.name == "" || this.formLabelAlign.name == null) {
        this.$message.error("变式名称不可为空");
        return;
      }
      if (this.formLabelAlign.name.length >= 30) {
        this.$message.error("变式名称字数不可超过30");
        return;
      }
      if (this.formLabelAlign.sn == "" || this.formLabelAlign.sn == null) {
        this.$message.error("变式分组不可为空");
        return;
      }
      if (this.formLabelAlign.type == "" || this.formLabelAlign.type == null) {
        this.$message.error("变式位置不可为空");
        return;
      }
      this.allow_go = false;
      if (this.formLabelAlign.title == "编辑变式") {
        var obj = {
          "state": 0,
          id: this.formLabelAlign.id,
          specialTopicId: this.formLabelAlign.specialTopicId,
          name: this.formLabelAlign.name,
          sn: this.formLabelAlign.sn,
          type: this.formLabelAlign.type,
          difficulty: this.formLabelAlign.difficulty,
        };
        CaseUpdate(obj).then((res) => {
          if (res.message == "请求成功") {
            this.dialogVisible = false;
            setTimeout(() => {
              this.allow_go = true;
            }, 1000);
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.getCaseList(); //刷新数据
          } else {
            this.$message.error("变式已和活动绑定无法编辑");
            setTimeout(() => {
              this.allow_go = true;
            }, 1000);

          }
        });
      } else {
        CaseAdd({
          specialTopicId: this.$store.state.chapter.id, //专题id
          name: this.formLabelAlign.name,
          difficulty: this.formLabelAlign.difficulty, //难度
          sn: this.formLabelAlign.sn, //分组
          type: this.formLabelAlign.type, //位置
        }).then((res) => {
          if (res.message == "请求成功") {
            this.dialogVisible = false;
            setTimeout(() => {
              this.allow_go = true;
            }, 1000);
            this.$message({
              message: "创建成功",
              type: "success",
            });
            this.getCaseList(); //刷新数据
          } else {
            this.$message.error("创建失败");
            setTimeout(() => {
              this.allow_go = true;
            }, 1000);
          }
        });
      }
    },
    //编辑
    handleEdit (index, row) {
      if (
        this.$store.state.grade &&
        this.$store.state.subject &&
        this.$store.state.chapter
      ) {
        let data = JSON.parse(JSON.stringify(row));
        this.formLabelAlign = data;
        this.formLabelAlign["title"] = "编辑变式";
        this.dialogVisible = true;
      } else {
        this.$message.error("请选择年级丶学科以及章节");
        return;
      }
    },
    //进入   编辑变式详情
    handleEnter (index, row) {
      this.$router.push({ name: "particulars", params: { id: row.id } });
    },
    //删除
    handleDelete (index, row) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var id = row.id;
          CaseDelete([id]).then((res) => {
            if (res.message == "请求成功") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getCaseList(); //刷新数据
            } else {
              this.$message.error("该变式已经用于学生答题不能再做修改");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //关闭弹窗
    handleClose (done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          var param = {
            grade: this.$store.state.grade.value,
            subject: this.$store.state.subject.value,
            specialtopicType: 0,
          };
          getSpecialtopic(param).then((res) => {
            res.data.map((item, index) => {
              item["number"] = index + 1;
            });
            store.commit("setChapterData", res.data);
          });
          done();
        })
        .catch((_) => { });
    },
    //分页
    changePaging (val) {
      store.commit("setPage", val);
      this.getCaseList();
    },
    //新建章节分页
    changespecialPaging (val) {
      this.specialPaging = val; //
      this.specialtopicList();
    },
  },
  watch: {},

  created () { },
  mounted () {
    this.init();
  },
};
</script>

<style lang='less' scoped>
@import '../../../style/k12SetQuestion/index.less';
@import '../../../style/mouse.less';
</style>